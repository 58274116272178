<template>
  <div class="fill-height">
    <div class="d-block d-sm-none">
      <v-btn :ripple="false" class="pl-0" color="white" elevation="0" large
             @click="$emit('openMenu', true)">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </div>
    <v-container class="pa-0 pt-sm-3 px-sm-3 componentHeight" style="max-width: 100vh;">
      <video-player id="videoPlayer" ref="videoPlayer" :options="playerOptions" :playsinline="true"
                    class="video-player-box"
                    @play="computeLayout" @ready="computeLayout"></video-player>
      <div :style="`height: calc(100% - ${videoPlayerHeight}px);`" style="position: relative; min-height: 48px;">
        <div style="overflow-y: auto; height: 100%;">
          <div v-for="(item, index) of cctv" :key="index" :class="(item.id===activeId) ? 'active' : ''"
               :data-id="item.id" class="cctvSelector px-5 py-3"
               @click="setActive(item)">
            <div style="font-size: 16px;">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="inputContainer" style="position: absolute; top: 4px; right: 12px;">
          <v-text-field v-model="keyword" append-icon="mdi-close" background-color="white" dense hide-details outlined
                        placeholder="ค้นหา"
                        @click:append="keyword = ''"></v-text-field>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import Vue from 'vue'
import 'video.js/dist/video-js.css'
import { videoPlayer } from 'vue-video-player'
import { mapState } from 'vuex'

const hls = require('videojs-contrib-hls')
Vue.use(hls)

export default {
  name: 'cctv',
  components: {
    videoPlayer
  },
  async mounted () {
    this.computeLayout()
    this.originalCctv = await this.$store.dispatch('getCctv', { uuid: this.uuid })
    window.addEventListener('resize', this.computeLayout)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.computeLayout)
  },
  data () {
    return {
      keyword: '',
      originalCctv: [],
      activeId: 0,
      videoPlayerHeight: 0,
      playerOptions: {
        muted: true,
        language: 'en',
        fluid: true,
        loop: true,
        sources: [
          {
            src: '',
            type: 'application/x-mpegURL'
          }
        ]
      }
    }
  },
  methods: {
    computeLayout () {
      const videoPlayer = document.getElementById('videoPlayer')
      this.videoPlayerHeight = videoPlayer.offsetHeight
    },
    setActive (item) {
      this.activeId = item.id
      const elemArray = [...document.getElementsByClassName('cctvSelector')]
      elemArray.forEach(ele => {
        ele.classList.remove('active')
        if (String(ele.dataset.id) === String(item.id)) {
          ele.classList.add('active')
          this.playerOptions.sources[0].src = item.path
          this.playerOptions = { ...this.playerOptions }
        }
      })
    }
  },
  computed: {
    ...mapState('user', ['uuid']),
    cctv () {
      if (this.keyword) {
        return this.originalCctv.filter(obj => obj.name.includes(this.keyword))
      } else {
        return this.originalCctv
      }
    }
  },
  watch: {
    originalCctv: {
      handler (to, from) {
        if (to.length) {
          this.playerOptions.sources[0].src = to[0].path
          this.activeId = to[0].id
          this.playerOptions = { ...this.playerOptions }
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>
@media (max-width: 600px) {
  .componentHeight {
    height: calc(100% - 44px);
  }

  .inputContainer {
    max-width: 150px;
  }
}

@media (min-width: 600px) {
  .componentHeight {
    height: 100%;
  }
}

.cctvSelector {
  background-color: black;
  color: white;
}

.cctvSelector:hover {
  background: slategrey;
}

.cctvSelector.active {
  background: green;
}
</style>
